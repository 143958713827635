@import "../main.less";

.mainWrapper {
  background-color: transparent;
}

.features_section {
  padding-top: 167px;
  background-image: url(../../images/aboutbg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
  padding-bottom: 240px;

  @media screen and (max-width: @screen-lg) {
    padding-top: 60px;
    padding-bottom: 210px;

  }

  @media screen and (max-width: @screen-md) {
    padding-top: 50px;
    padding-bottom: 150px;
  }

  .container {
    max-width: 1240px;
  }

  .top_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    @media screen and (max-width: @screen-md) {
      gap: 0px;
    }

    .left_side {
      flex: 0 0 calc(50% - 20px);

      @media screen and (max-width: @screen-md) {
        flex: 0 0 100%;

        .heading {
          padding-bottom: 0;
        }
      }

      * {
        text-align: left;
      }
    }

    .right_side {
      flex: 0 0 calc(50% - 20px);
      padding-top: 30px;

      @media screen and (max-width: @screen-md) {
        flex: 0 0 100%;
        padding-top: 0;
      }

      p {
        margin: 0;
        font-size: 1.6rem;

        @media screen and (max-width: @screen-md) {
          font-size: 1.4rem;
        }
      }
    }
  }

  .features_blog_main {
    position: relative;

    img.common {
      position: absolute;
      top: 29%;

      @media screen and (max-width: @screen-md) {
        display: none;
      }

      &.left {
        left: 9%;
      }

      &.right {
        right: 11%;
        top: 34%;
      }

    }

    &:before {
      content: "";
      position: absolute;
      right: 0px;
      top: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      background-image: url(../../images/dot_ball.png);
      width: 138px;
      height: 220px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: -10%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      background-image: url(../../images/left_circle.png);
      width: 7%;
      height: 27%;
    }

    .features_blog {
      &:before {
        content: "";
        position: absolute;
        right: 0px;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        background-image: url(../../images/shadow_ball.png);
        width: 17%;
        height: 60%;
      }

      &:after {
        content: "";
        position: absolute;
        left: 45px;
        bottom: 25%;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 100%;
        background-image: url(../../images/horizental_dots.png);
        width: 12%;
        height: 27%;
      }
    }


    .features_blog_inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      margin-top: 73px;

      @media screen and (max-width: @screen-lg) {
        gap: 10px;
        margin-top: 60px;
      }

      @media screen and (max-width: @screen-md) {
        gap: 0px;
        margin-top: 40px;
      }
    }

    .blog {
      flex: 0 0 calc(50% - 20px);
      border-radius: 6px;
      background: #F5F6FF;
      padding: 94px 91px 70px 28px;
      position: relative;
      margin-bottom: 57px;
      transition: all 0.5s ease-in-out;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      *{
        position: relative;
        z-index: 1;
      }
      &:after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(70, 85, 199, 0.8);
        opacity: 0;
        border-radius: 6px;
        transition: all 0.5s ease-in-out;

      }

      @media screen and (max-width: @screen-lg) {
        flex: 0 0 calc(50% - 10px);
        margin-bottom: 65px;
        padding: 70px 20px 20px 20px;
      }

      @media screen and (max-width: @screen-md) {
        flex: 0 0 100%;
        padding: 60px 10px 20px 10px;
        margin-bottom: 40px;
      }

      .icon {
        width: 108px;
        height: 122px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -47px;
        left: 30px;
        border-radius: 10px;

        @media screen and (max-width: @screen-lg) {
          width: 85px;
          height: 85px;

          span {
            width: 50px;
          }
        }

        @media screen and (max-width: @screen-md) {
          width: 70px;
          height: 70px;
          top: -20px;

          span {
            width: 40px;
          }
        }

        span {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          max-width: 70px;
          margin: 0 auto;

            img{
              filter: invert(100%);
            }
        }
      }

      &:hover {
        background-image: url(../../images/bg_box.png);
        h3,
        p {
          color: @white;
        }

        path {
          fill: #0C1347;
        }
        &::after{
          opacity: 1;
        }
      }
    }

    h3 {
      margin: 0;
      color: @secondary-color;
      font-size: 2.2rem;
      font-weight: 600;
      padding-bottom: 15px;

      @media screen and (max-width: @screen-md) {
        font-size: 1.8rem;
        padding-bottom: 0px;
      }
    }

    p {
      margin: 0;
      font-size: 1.6rem;

      @media screen and (max-width: @screen-md) {
        font-size: 1.4rem;
      }
    }

    @media screen and (max-width: @screen-md) {

      &:after,
      &:before {
        display: none;
      }
    }

  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 90px;
    width: 526px;
    height: 127px;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-image: url(../../images/crop.png);
  }

  @media screen and (max-width: @screen-lg) {
    &:before {
      width: 336px;
      height: 97px;
    }
  }

  @media screen and (max-width: @screen-md) {
    &:before {
      display: none;
    }
  }
}