@import "../main.less";

.what_we_offer_section {
  padding-top: 100px;
  padding-bottom: 199px;
  position: relative;

  @media screen and (max-width: @screen-xl) {
    padding-bottom: 70px;
  }

  @media screen and (max-width: @screen-md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  img.common {
    position: absolute;
    top: 20%;

    @media screen and (max-width: @screen-md) {
      display: none;
    }

    &.left {
      left: 9%;
    }

    &.right {
      right: 11%;
    }
  }

  .read_more_button {
    position: relative;
    top: 103px;

    @media screen and (max-width: @screen-xl) {
      top: 30px;
    }

    @media screen and (max-width: @screen-md) {
      position: static;
      margin-top: 20px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    right: 52px;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    background-image: url(../../images/vertical_dots.png);
    width: 6%;
    height: 33%;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 33px;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../images/about_bg.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50%;
  }

  .container {
    max-width: 1400px;
    display: block;
    margin: auto;
    position: relative;
    z-index: 1;
  }

  .about_main_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: @screen-md) {
      gap: 10px;
    }

    @media screen and (max-width: 479px) {
      gap: 0px;
    }
  }

  .about_item {
    flex: 0 0 calc(33.3333% - 20px);
    border-radius: 20px;
    background-color: @white;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
    padding: 25px 25px 63px 25px;
    margin-bottom: 20px;

    @media screen and (max-width: @screen-xl) {
      flex: 0 0 calc(50% - 20px);
    }

    @media screen and (max-width: @screen-md) {
      flex: 0 0 calc(50% - 10px);
      padding: 20px 20px 20px 20px;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 479px) {
      flex: 0 0 100%;
    }
  }

  h3 {
    margin: 0;
    color: @secondary-color;
    font-size: 2.5rem;
    font-weight: 700;
    display: flex;
    align-items: center;

    @media screen and (max-width: @screen-md) {
      font-size: 2rem;
    }

    span {
      width: 100%;
      max-width: 60px;
      margin-right: 20px;

      @media screen and (max-width: @screen-md) {
        max-width: 45px;
        margin-right: 10px;
      }
    }
  }

  p {
    margin: 0;
    padding-top: 20px;
    color: @text_light_black;

    @media screen and (max-width: @screen-md) {
      padding-top: 0;
    }
  }
}

.the_story {
  position: relative;

  &:before,
  &:after {
    content: "";
    border-radius: 321px;
    background: #AEE7FF;
    filter: blur(180px);
    width: 19%;
    height: 31%;
    display: block;
    position: absolute;
    top: 20%;
    left: -10%;
    right: auto;
    z-index: 0;
  }

  &:after {
    content: "";
    background: #f1aef2;
    top: 40%;
    left: auto;
    right: -10%;
  }
}

.histiry_main_block {
  position: relative;
  margin-top: 60px;

  @media screen and (max-width: @screen-md) {
    margin-top: 20px;
  }

  .history_item_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 960px;
    margin: 0 auto;
    position: relative;
    align-items: center;

    .year {
      flex: 0 0 calc(50% - 50px);
      text-align: right;
      color: @secondary-color;
      font-size: 2.4rem;
      font-weight: 700;

      @media screen and (max-width: @screen-md) {
        font-size: 2rem;
        flex: 0 0 calc(50% - 20px);
      }
    }

    .history_item {
      border-radius: 10px;
      background: @white;
      box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
      padding: 30px 30px;
      border-left: solid 5px #7a7de7;
      flex: 0 0 calc(50% - 50px);
      min-height: 180px;
      position: relative;
      z-index: 1;

      @media screen and (max-width: @screen-md) {
        padding: 10px 10px;
        flex: 0 0 calc(50% - 20px);
        min-height: inherit;
        border-left-width: 2px;

        &::before {
          left: -30px !important;
          width: 15px !important;
          height: 15px !important;
        }
      }

      &:before {
        content: "";
        position: absolute;
        left: -66px;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: #bfb9f6;
      }

      h3 {
        margin: 0;
        color: @secondary-color;
        font-size: 1.8rem;
        font-weight: 700;

        @media screen and (max-width: @screen-md) {
          font-size: 1.5rem;
        }
      }

      p {
        margin: 0;
        padding-top: 10px;
        color: @text_light_black;

        @media screen and (max-width: @screen-md) {
          padding-top: 0;
          font-size: 1.3rem;
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: @primary-color;
      height: 100%;
      top: 0;
      bottom: 0;
      width: 1px;
    }

    &:nth-child(2) {
      .history_item {
        border-color: #ff8522;
        order: -1;

        &:before {
          background-color: #ff8522;
          left: inherit;
          right: -60px;
        }

        @media screen and (max-width: @screen-md) {
          &:before {
            right: -27px;
            left: inherit !important;
          }
        }
      }

      .year {
        text-align: left;
      }
    }

    &:nth-child(3) {
      .history_item {
        border-color: #ff3b3b;

        &:before {
          background-color: #ff3b3b;
        }
      }
    }

    &:nth-child(4) {
      .history_item {
        border-color: #ff8522;
        order: -1;

        &:before {
          background-color: #ff8522;
          left: inherit;
          right: -60px;
        }

        @media screen and (max-width: @screen-md) {
          &::before {
            right: -27px;
            left: inherit !important;
          }
        }
      }

      .year {
        text-align: left;
      }
    }

    &:nth-child(5) {
      .history_item {
        border-color: #50bfa5;

        &:before {
          background-color: #50bfa5;
        }
      }
    }
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    background-color: @primary-color;
  }

  &::before {
    top: inherit;
    bottom: 0;
  }
}

.logo_main_section {
  padding-top: 100px;
  padding-bottom: 230px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 90px;
    width: 526px;
    height: 127px;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-image: url(../../images/crop.png);
  }

  @media screen and (max-width: @screen-lg) {
    &:before {
      width: 336px;
      height: 97px;
    }
  }

  @media screen and (max-width: @screen-md) {
    &:before {
      display: none;
    }
  }

  @media screen and (max-width: @screen-md) {
    padding-top: 40px;
    padding-bottom: 150px;
  }

  .logo_main_content {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      background-image: url(../../images/horizental_dots.png);
      width: 12%;
      height: 100%;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      background-image: url(../../images/left_circle.png);
      width: 7%;
      height: calc(100% + 20px);
    }
  }

  .container {
    max-width: 1400px;
    display: block;
    margin: auto;
  }

  .logo_main_block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1190px;
    margin: 0 auto;
    padding-top: 15px;

    @media screen and (max-width: @screen-xl) {
      justify-content: center;
    }

    @media screen and (max-width: @screen-md) {
      gap: 10px;
      padding-top: 0;
    }
  }

  .logo_img_block {
    flex: 0 0 calc(25% - 20px);
    border-radius: 14px;
    background: @white;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.06);
    padding: 15px;
    text-align: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 20px;

    @media screen and (max-width: @screen-xl) {
      flex: 0 0 calc(33.33333% - 20px);
    }

    @media screen and (max-width: @screen-md) {
      flex: 0 0 calc(50% - 5px);
      border-radius: 8px;
      padding: 5px 10px;
      margin-bottom: 0px;
    }

    img {
      width: 100%;
      min-width: 150px;
      object-fit: none;
      object-position: center;
      aspect-ratio: 16/10;
      max-height: 100px;

      @media screen and (max-width: @screen-md) {
        min-width: inherit;
        aspect-ratio: inherit;
        max-height: 70px;
        object-fit: inherit;
        width: auto;
      }
    }
  }
}

.leader {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-bottom: 100px;
  align-items: center;
  position: relative;

  @media screen and (max-width: @screen-lg) {
    justify-content: center;
    padding-bottom: 50px;
  }

  @media screen and (max-width: @screen-md) {
    padding-bottom: 50px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 52px;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
    background-image: url(../../images/vertical_dots.png);
    width: 6%;
    height: 33%;
  }

  button.slick-arrow {
    display: none !important;
  }

  .content_part {
    width: 100%;
    max-width: 439px;
    padding-right: 25px;
    padding-top: 160px;

    @media screen and (max-width: @screen-lg) {
      padding-top: 0px;
      padding-right: 0;
    }

    @media screen and (max-width: @screen-md) {
      max-width: 100%;
      padding-right: 0px;
      padding-top: 0px;
    }

    .btn_wrap {
      padding-top: 60px;
      display: flex;
      align-items: center;
      gap: 20px;

      @media screen and (max-width: @screen-lg) {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      @media screen and (max-width: @screen-md) {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .icon {
        cursor: pointer;
        width: 60px;
        height: auto;
        transform: rotate(0deg);

        @media screen and (max-width: @screen-lg) {
          width: 45px;
        }

        @media screen and (max-width: @screen-md) {
          width: 30px;
        }

        path {
          fill: @text_light_black;
        }

        &:hover {
          path {
            fill: @secondary-color;
          }
        }
      }

      .icon.prev {
        transform: rotate(180deg);
      }
    }

    .heading {
      padding: 0;
    }

    * {
      text-align: left;
    }

    p {
      margin: 0;
      color: @text_light_black;
      line-height: 1.6;
      font-size: 1.6rem;

      @media screen and (max-width: @screen-md) {
        font-size: 1.4rem;
      }
    }
  }
}

.leader_main_block {
  width: 100%;
  max-width: 60%;

  @media screen and (max-width: @screen-xl) {
    max-width: 100%;
  }

  .leader_block {
    padding-left: 40px;
    text-align: center;
    position: relative;

    @media screen and (max-width: @screen-xl) {
      padding: 0px 15px;
    }

    @media screen and (max-width: @screen-md) {
      padding: 0px 10px;
    }
  }

  .image_block {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 100px;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: calc(100% - 100px);
      background-color: #adb6f8;
      border-radius: 10px;
      z-index: -1;
    }
  }

  .content {
    position: absolute;
    left: 40px;
    bottom: 0;
    z-index: 1;
    border-radius: 0px 20px 0px 0px;
    background: @white;
    width: 85%;
    text-align: left;
    padding: 10px 46px;

    @media screen and (max-width: @screen-xl) {
      left: 15px;
      padding: 10px 20px;
    }

    @media screen and (max-width: @screen-md) {
      padding: 5px 5px;
      border-radius: 0px 10px 0px 0px;
      left: 10px;
    }

    p {
      margin: 0;
      color: @primary-color;
      font-size: 1.9rem;
      font-weight: 700;
      letter-spacing: 5.7px;

      @media screen and (max-width: @screen-md) {
        letter-spacing: 0;
        font-size: 1.4rem;
      }
    }

    h3 {
      margin: 0;
      color: @secondary-color;
      font-size: 2.6rem;
      font-weight: 600;
      padding-top: 10px;

      @media screen and (max-width: @screen-xl) {
        font-size: 2.2rem;
        padding-top: 0;
      }

      @media screen and (max-width: @screen-md) {
        letter-spacing: 0;
        font-size: 2rem;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    max-height: 480px;
    margin: 0 auto;
  }

}


.about_company_section {
  padding: 166px 0px 74px 0px;
  position: relative;

  @media screen and (max-width: @screen-lg) {
    padding: 70px 0px 50px 0px;
  }

  @media screen and (max-width: @screen-md) {
    padding: 50px 0px 0px 0px;
  }

  &:before,
  &:after {
    content: "";
    border-radius: 321px;
    background: rgba(77, 93, 218, 0.89);
    filter: blur(180px);
    width: 19%;
    height: 31%;
    display: block;
    position: absolute;
    top: 20%;
    left: -10%;
    right: auto;
    z-index: 0;
  }

  &:after {
    content: "";
    background: #f1aef2;
    top: 40%;
    left: auto;
    right: -10%;
  }

  .about_inner {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      background-image: url(../../images/croptouch.png);
      width: 9%;
      height: 41%;
    }
  }

  .container {
    max-width: 1310px;
    display: block;
    margin: auto;
  }

  .about_inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;

    @media screen and (max-width: @screen-md) {
      gap: 0px;
    }
  }

  .left_side {
    flex: 0 0 calc(50% - 15px);
    position: relative;
    padding-bottom: 50px;

    @media screen and (max-width: @screen-md) {
      flex: 0 0 100%;
      padding-bottom: 0;
    }

    .image_top {
      width: 100%;
      max-width: 52%;
      position: relative;
      padding-bottom: 33%;

      img {
        border-radius: 10px;
      }

      &:after {
        content: "";
        position: absolute;
        left: -25%;
        bottom: -20px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        background-image: url(../../images/dots_img.png);
        width: 70%;
        height: 35%;
      }
    }

    .image_bottom {
      position: absolute;
      right: 0;
      top: 142px;
      width: 100%;
      max-width: 67%;

      img {
        border-radius: 10px;
      }

      .video_play_button {
        position: absolute;
        bottom: 40%;
        left: -10%;
        width: 138px;
        height: 138px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 111px;
          max-width: 111px;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 100%;
          margin: 0 auto;
        }


        // &:before{
        //   content: "";
        //   position: absolute;
        //   left: 0;
        //   right: 0;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   height: 138px;
        //   max-width: 138px;
        //   background-color: rgba(255, 255, 255, 0.39);
        //   border-radius: 100%;
        //   margin: 0 auto;
        // }
        button {
          svg {
            width: 30px;
          }
        }

        &:before {
          content: "";
          position: absolute;
          width: 138px;
          height: 138px;
          -webkit-animation-delay: 0s;
          animation-delay: 2s;
          -webkit-animation: pulsate1 2s;
          animation: pulsate1 2s;
          -webkit-animation-direction: forwards;
          animation-direction: forwards;
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-timing-function: steps;
          animation-timing-function: steps;
          opacity: 1;
          border-radius: 50%;
          border: 10px solid rgba(255, 255, 255, 0.7);
          top: -11px;
          left: -11px;

          @-webkit-keyframes pulsate1 {
            0% {
              -webkit-transform: scale(0.6);
              transform: scale(0.6);
              opacity: 1;
              box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
            }

            100% {
              -webkit-transform: scale(1);
              transform: scale(1);
              opacity: 0;
              box-shadow: none;

            }
          }

          @keyframes pulsate1 {
            0% {
              -webkit-transform: scale(0.6);
              transform: scale(0.6);
              opacity: 1;
              box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
            }

            100% {
              -webkit-transform: scale(1, 1);
              transform: scale(1);
              opacity: 0;
              box-shadow: none;

            }
          }
        }

        button {
          width: 94px;
          height: 94px;
          background-color: rgba(249, 163, 64, 1);
          border-radius: 100%;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      img {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: @screen-md) {
        top: 110px;
      }
    }

    .counter {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      max-width: 51%;
      padding: 10px 23px;

      @media screen and (max-width: @screen-md) {
        padding: 10px 15px;
      }

      h2 {
        margin: 0;
        color: @primary-color;
        font-size: 7.2rem;
        font-weight: 700;
        line-height: 1;

        @media screen and (max-width: @screen-xl) {
          font-size: 6rem;
        }

        @media screen and (max-width: @screen-md) {
          font-size: 5rem;
        }
      }

      p {
        margin: 0;
        font-size: 2.5rem;
        font-weight: 400;

        @media screen and (max-width: @screen-xl) {
          font-size: 2rem;
        }


        @media screen and (max-width: @screen-md) {
          font-size: 1.4rem;
        }
      }
    }

  }

  .right_side {
    flex: 0 0 calc(50% - 15px);
    padding-top: 10px;

    @media screen and (max-width: @screen-md) {
      flex: 0 0 100%;
      padding-top: 50px;
    }

    .heading {
      padding: 0px 0px 8px 0px;
    }

    * {
      text-align: left;
    }

    p {
      margin: 0;
      color: @text_light_black;
      line-height: 1.6;
      font-size: 1.6rem;

      @media screen and (max-width: @screen-md) {
        font-size: 1.4rem;
      }
    }

    .tab_section {
      display: block;
      padding-top: 26px;
      position: relative;
      z-index: 1;

      @media screen and (max-width: @screen-md) {
        padding-top: 10px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: solid 1px #E2E2E2;
        padding: 10px 0;
      }

      li {
        list-style: none;

        button {
          background-color: transparent;
          box-shadow: none;
          border: none;
          color: @secondary-color;
          font-weight: 700;
          font-size: 2rem;
          padding: 5px 20px;
          height: auto;
          border-radius: 40px;

          @media screen and (max-width: @screen-xl) {
            padding: 5px 10px;
            font-size: 1.6rem;
          }

          @media screen and (max-width: @screen-md) {
            padding: 5px 9px;
            border-radius: 20px;
            font-size: 1.3rem;
          }

          &:hover,
          &.active {
            background-color: #F9A340;
            color: @white;
            border-color: #F9A340;
          }
        }
      }
    }

    .tab_content {
      padding-top: 30px;

      @media screen and (max-width: @screen-md) {
        padding-top: 10px;
      }
    }

    .descreption {
      ul {
        padding: 0;
        border: none;
        margin-top: 19px;
        margin-left: 7px;

        @media screen and (max-width: @screen-md) {
          margin-top: 10px;
          margin-left: 5px;
        }

        li {
          color: @text_light_black;
          line-height: 1.6;
          font-size: 1.6rem;
          padding: 5px 0px 5px 40px;
          background-repeat: no-repeat;
          background-position: left center;
          background-size: auto;
          background-image: url(../../images//arrow.png);

          @media screen and (max-width: @screen-md) {
            font-size: 1.4rem;
            background-size: 20px;
          }
        }
      }
    }
  }
}

.video_modal {
  margin: 0 auto;
  padding: 0;

  .ant-modal-body {
    line-height: 0;
  }

  iframe {
    border: none;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .ant-modal-content {
    padding: 0;
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }

  button.ant-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: rgb(251, 130, 73);
    border-radius: 0;
    transition: all 0.5s ease-in-out;

    &:hover {
      background: @innerbannerbgcolor;

      svg {
        fill: @white;
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }

    @media screen and (max-width: @screen-xl) {
      width: 30px;
      height: 30px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
} 