@import "../variables.less";

// Features
#Features {
  text-align: left;
  padding: 200px 20px 0;
  .container {
    max-width: 1400px;
  }

  .btn_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .icon {
      cursor: pointer;
      width: 60px;
      height: auto;
      transform: rotate(0deg);
      path {
        fill: @text_light_black;
      }
      &.prev {
        transform: rotate(180deg);
      }
      &:is(:hover, :focus-within) {
        path {
          fill: @secondary-color;
        }
      }
    }
  }
  .moduleList {
    padding: 0 0;
    background-color: transparent;
    max-width: 1700px;
    margin: auto;
    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .slick-slide {
      margin: 16px;
      padding: 40px 0 0;
      position: relative;
      transition: all 0.5s ease-in-out;
      height: 100%;

      &:is(:hover, :focus-within, :focus-visible, :focus) {
        padding-top: 0px;
        margin: 0px 16px 0;
      }
    }
    .slick-dots {
      width: 100%;
      max-width: 1200px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      padding: 0;
      margin: 0 auto;
      display: flex !important;
      align-items: center;
      justify-content: center;
      // background-color: fade(@action_btn_color, 10%);
      border-radius: 7px;
      li {
        width: 100%;
        height: 2px;
        margin: 0;
        button {
          padding: 0;
          margin: 0;
          width: 100%;
          &:before {
            width: 100%;
            height: 2px;
            background-color: fade(@action_btn_color, 10%);
            opacity: 1;
            content: "";
          }
        }
        &:first-child {
          button:before {
            border-radius: 7px 0 0 7px;
          }
        }
        &:last-child {
          button:before {
            border-radius: 0 7px 7px 0;
          }
        }
        &.slick-active {
          button:before {
            border-radius: 7px;
            height: 5px;
            margin: -2px 0 0;
            background-color: @primary-color;
            opacity: 1;
          }
        }
      }
    }
    .moduleItem {
      display: flex !important;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 15px;
      text-align: center;
      padding: 45px 30px;
      box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.06);
      background-color: @white;
      border: 1px solid @white;
      border-radius: 23px;
      position: relative;
      isolation: isolate;
      transition: all 0.5s ease-in-out;
      .systemModuleHoverImg {
        position: absolute;
        top: -80px;
        right: 0;
        z-index: -1;
        width: 500px;
        height: 500px;
        max-width: unset;
        height: auto;
        display: block;
        object-fit: cover;
        object-position: left;
        opacity: 0;
        transition: all 0.5s ease-in-out;
      }
      .icon {
        position: relative;
        text-align: center;
        margin: auto;
        svg {
          text-align: center;
          width: 98px;
          height: 98px;
          // path {
          //   fill: @primary-color;
          // }
        }
      }
      .listTitle {
        color: @secondary-color;
        text-align: center;
        font-size: 2.3rem;
        font-weight: 600;
        line-height: 1.3;
        text-transform: capitalize;
        margin: 10px auto;
        width: 90%;
      }
      .listDetails {
        color: @text_light_black;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.5;
        margin: auto;
        transition: all 0.5s ease-in-out;
      }

      &:is(:hover, :focus-within) {
        background-color: @accent-color;
        border-color: @accent-dark-color;
        box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.06);
        .listDetails {
          color: @black;
        }
        .systemModuleHoverImg {
          opacity: 1;
        }
      }
    }
  }
  @media (max-width: @screen-lg) {
    padding: 60px 20px 0;
  }
}
