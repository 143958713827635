@import "../variables.less";

// counterSection
#Pricing {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 140px 0 180px;
  isolation: isolate;

  .container {
    max-width: 1400px;
    display: block;
    margin: auto;
  }
  .sumarrySection {
    width: 55%;
    margin: 20px auto;
  }

  .planListing {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    gap: 20px;
    .box {
      width: 30%;
      border-radius: 23px;
      border: 1px solid @white;
      background-color: @white;
      box-shadow: 0px 4px 34px 0px fade(@black, 6%);
      padding: 28px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      .icon {
        width: 80px;
        height: 80px;
        margin: 0 auto 20px;
        padding: 0;
        border-radius: 80px;
        border: 1px solid fade(@black, 10%);
        display: grid;
        place-items: center;
        transition: all 0.5s ease-in-out;
        svg {
          width: 50px;
          height: auto;
          path {
            fill: @secondary-color;
            transition: all 0.5s ease-in-out;
          }
        }
      }
      .name {
        color: @secondary-color;
        text-align: center;
        font-size: 1.9rem;
        font-weight: 600;
        line-height: 1.4;
        text-transform: capitalize;
        margin: 0;
        padding: 0;
      }
      .rate {
        color: @secondary-color;
        text-align: center;
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.4;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        display: none;
        strong {
          font-size: 3rem;
          font-weight: 700;
        }
      }

      hr {
        display: block;
        width: 100%;
        height: 1px;
        background-color: fade(@black, 10%);
        border: none;
        margin: 30px auto;
        padding: 0;
      }
      ul {
        width: 100%;
        margin: 0 auto 40px;
        padding: 0;
        max-width: 250px;
        li {
          color: @black;
          text-align: left;
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 1.4;
          text-transform: capitalize;
          padding: 0 0 0 35px;
          margin: 0 0 10px;
          background-image: url("../../images/icons/checkMark.svg");
          background-repeat: no-repeat;
          background-position: 0 3px;
        }
      }

      &:is(:hover, :focus-within) {
        border-color: @primary-color;
        .icon {
          background-color: @primary-color;
          svg path {
            fill: @white;
          }
        }

        .buttonLink.primaryColor {
          color: @white;
          background-color: @primary-color;
          border-color: @primary-color;
          filter: drop-shadow(0px 4px 24px rgba(77, 93, 218, 0.38));
          &:is(:hover, :focus-within) {
            transform: translateY(-5px);
          }
        }
      }
    }
  }

  @media (max-width: @screen-lg) {
    .planListing {
      .box {
        width: 30%;
        padding: 20px 10px;
        ul {
          width: 90%;
          li {
            font-size: 1.6rem;
            padding: 0 0 0 30px;
          }
        }
      }
    }
  }

  @media (max-width: @screen-md) {
    .planListing {
      flex-wrap: wrap;
      .box {
        width: 100%;
        ul {
          max-width: 200px;
        }
      }
    }
  }
}
