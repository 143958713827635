.titleTag {
  color: @primary-color;
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.57em;
  text-transform: uppercase;
  text-align: left;
}

.titleSection {
  color: @secondary-color;
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.1;
  text-transform: capitalize;
  padding: 0;
  margin: 10px 0 0;
}
.sumarrySection {
  width: 80%;
  color: @text_light_black;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  &.text-center {
    margin: 20px auto;
    text-align: center;
  }
}

.buttonLink {
  padding: 1.8rem 3rem;
  margin: 0 30px 0 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid @primary-color;
  background-color: @primary-color;
  color: @white;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.4;

  &:last-child {
    background-color: transparent;
    border-color: fade(@black, 30%);
    color: @black;
    box-shadow: none;
    &.primaryColor {
      color: @primary-color;
    }
  }
  &:is(:hover, :focus-within) {
    background-color: @secondary-color;
    border-color: @secondary-color;
    color: @white;
    &.primaryColor {
      color: @white;
    }
  }
}
@media (max-width: @screen-lg) {
  .titleTag {
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 1.3;
    letter-spacing: 0.45em;
    margin-top: 10px;
  }
  .titleSection {
    font-size: 2.4rem;
    font-weight: 800;
    line-height: 1.3;
    letter-spacing: 0.032em;
    margin-top: 10px;
  }
  .sumarrySection {
    width: 100%;
  }
}

.circles {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: -200px;
  right: auto;
  transform: translateY(-50%);

  > div {
    animation: grow-and-fade 3s infinite ease-out;
    background-color: fade(@secondary-color, 80%);
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .circle1 {
    animation-delay: 1s;
  }
  .circle2 {
    animation-delay: 2s;
  }
  .circle3 {
    animation-delay: 3s;
  }
}

.pagenotfound__section {
  width: 100dvw;
  height: 100dvh;
  display: grid;
  place-items: center;
}
