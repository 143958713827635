@keyframes grow-and-fade {
  0%,
  100% {
    opacity: 0.25;
    transform: scale(0);
  }
  50% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes move-left-right {
  0%,
  100% {
    transform: translateX(-20px);
  }

  50% {
    transform: translateX(20px);
  }
}

@keyframes move-left-right-footer {
  0%,
  100% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(35px);
  }
}
@keyframes rotated {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.95);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes diving-rotate {
  0%,
  100% {
    transform: translateX(10px) translateY(-10px);
  }
  50% {
    transform: translateX(-10px) translateY(10px);
  }
}

@keyframes bubbles {
  0%,
  100% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}
