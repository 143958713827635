@import "../main.less";

// Banner
#banner {
  padding: 10px 0;
  position: relative;
  z-index: 0;
  isolation: isolate;

  &::before,
  &::after {
    content: "";
    border-radius: 321px;
    background: rgba(77, 93, 218, 0.89);
    filter: blur(180px);
    width: 320px;
    height: 320px;
    display: block;
    position: absolute;
    top: 20%;
    left: -10%;
    right: auto;
    z-index: 0;
  }
  &::after {
    content: "";
    background: #f1aef2;
    top: 40%;
    left: auto;
    right: -10%;
  }
  .bannerImgLeft,
  .bannerImgRight {
    position: absolute;
    top: 15%;
    left: auto;
    right: 0;
    z-index: -1;
    animation: move-left-right 4s ease-in-out infinite;
  }
  .bannerImgLeft {
    top: 103%;
    left: 0;
    right: auto;
  }

  .container {
    max-width: 1400px;
    display: grid;
    place-items: center;
    height: 100%;
    position: relative;
  }

  .imageSection {
    position: relative;
    z-index: 0;
    // isolation: isolate;
    text-align: center;

    .bannerImg {
      object-fit: contain;
      object-position: center;
      width: auto;
      max-height: 600px;
      position: relative;
      z-index: 1;
      animation: diving-rotate 5s linear infinite;
    }
  }

  .contentSection {
    margin-top: 30px;
    .mainHeadingOne,
    .mainHeadingTwo,
    .mainHeadingThree {
      color: @primary-color;
      font-size: 5rem;
      font-weight: 800;
      line-height: 1.1;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }
    .mainHeadingTwo {
      color: @secondary-color;
    }
    .mainHeadingThree {
      color: @secondary-color;
      font-size: 7rem;
      line-height: 0.9;
      letter-spacing: -0.032em;
    }
    p {
      color: @text_light_black;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.5;
      display: block;
      width: 75%;
      margin: 20px 0 80px;
    }
    .buttonLink {
      padding: 1.8rem 3rem;
      margin: 0 30px 0 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: 1px solid @primary-color;
      background-color: @primary-color;
      color: @white;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.4;
      height: auto;

      &:last-child {
        background-color: transparent;
        border-color: fade(@black, 30%);
        color: @black;
        box-shadow: none;
      }
      &:is(:hover, :focus-within) {
        background-color: @secondary-color;
        border-color: @secondary-color;
        color: @white;
        svg path {
          fill: @white;
        }
      }
    }
  }

  @media (max-width: @screen-lg) {
    .container {
      padding: 0 15px;
    }
    .contentSection {
      margin-top: 30px;
      .mainHeadingOne,
      .mainHeadingTwo,
      .mainHeadingThree {
        font-size: 2.5rem;
        letter-spacing: -0.04em;
      }
      .mainHeadingThree {
        font-size: 3.8rem;
        line-height: 0.9;
        letter-spacing: -0.032em;
      }
      p {
        color: @black;
        width: 100%;
        margin: 20px 0;
      }
      .buttonLink {
        padding: 1rem 2rem;
        margin: 15px 0 0;
        width: 75%;
      }
    }
    .imageSection {
      .bannerImg {
        max-height: 250px;
      }
    }
    .bannerImgLeft,
    .bannerImgRight {
      width: 200px;
      top: 0;
    }
    .bannerImgLeft {
      position: relative;
      z-index: -1;
      margin-top: 40px;
    }
  }
}

// About
#About {
  position: relative;
  padding: 150px 0;
  isolation: isolate;
  .EllipseLeft {
    width: 360px;
    position: absolute;
    left: -120px;
    top: 0;
    z-index: -1;
    animation: rotated 4s ease-in-out infinite;
  }
  .EllipseRight {
    width: 500px;
    position: absolute;
    right: -200px;
    top: 80px;
    z-index: -1;
    animation: rotated 4s ease-in-out infinite;
  }
  .DotsRight {
    width: 230px;
    position: absolute;
    right: 80px;
    bottom: 80px;
    z-index: -1;
    animation: move-left-right 4s ease-in-out infinite;
  }
  .container {
    max-width: 1400px;
  }

  .contentSection {
    position: relative;
    z-index: 1;
    p {
      color: @text_light_black;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.6;
    }
    .checkList {
      margin: 0 0 50px;
      padding: 0;
      li {
        margin: 0 0 15px;
        padding: 0 0 0 45px;
        color: @black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-transform: capitalize;
        background-image: url("../../images/checkList.svg");
        background-repeat: no-repeat;
        background-position: 0px 3px;
        background-size: 25px 25px;
      }
    }
  }

  .LaptopImg {
    width: 80%;
    max-width: 40dvw;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: @screen-xl) {
    .LaptopImg {
      max-width: 50dvw;
      margin-top: 10%;
    }
  }

  @media (max-width: @screen-lg) {
    padding: 50px 0;
    .LaptopImg {
      position: relative;
      margin: 0;
      max-width: 80dvw;
    }
    .contentSection {
      padding: 50px 30px 0;
    }
  }
  @media (max-width: @screen-md) {
    padding: 50px 0;
    .contentSection {
      padding: 30px 0px 0;
    }
  }
}

// LogoSlider
#LogoSlider {
  padding: 90px 50px 0;
  display: none;
  .container {
    max-width: 1400px;
  }
  .logoList {
    .slick-slide {
      padding: 12px;
      div {
        margin-bottom: 12px;
        border-radius: 14px;
        background-color: @white;
        box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.06);
        text-align: center;
      }
    }
    .logoItem {
      display: block;
      padding: 0px;
      margin: 0 auto;
      width: 75% !important;
      aspect-ratio: 16/9;
      object-fit: contain;
      object-position: center;
    }
  }
  @media (max-width: @screen-md) {
    padding: 40px 10px 20px;
    margin: 50px auto 0;
  }
}
