@import "../variables.less";

// Testimonial
#Testimonial {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0 0;
  isolation: isolate;
  display: none;
  .EllipseLeft {
    width: 360px;
    position: absolute;
    left: -120px;
    top: 0;
    z-index: -1;
    animation: rotated 4s ease-in-out infinite;
  }

  svg.qoute {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 3%;
    left: 55%;
    transform: translateX(-50%);
    z-index: 2;
  }
  .sliderContainer {
    width: 80%;
    max-width: 1200px;
    min-height: 400px;
    margin: 0 auto;
    padding: 50px 0;
    background-repeat: no-repeat;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    .testimonialItem {
      text-align: center;
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      p {
        padding: 20px 8% 0;
        color: @text_dark_black;
        text-align: center;
        font-size: 2rem;
        font-style: italic;
        font-weight: 600;
        line-height: 1.4;
        letter-spacing: 0.04em;
      }
      .name,
      .post {
        color: @primary-color;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.4;
        text-transform: capitalize;
        margin: 0;
        padding: 0;
      }
      .post {
        color: @text_dark_black;
        font-size: 1.8rem;
      }
    }

    .thumbnailSliderWrap {
      margin: 30px auto 0;
      height: 160px;
      position: relative;
      .slick-track {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .slick-slide {
        text-align: center;
        width: 110px !important;
        .slideBox {
          width: 160px !important;
          position: relative;
          z-index: 0;
          border-radius: 160px;
          transition: all 0.5s ease-in-out;
          cursor: pointer;
          img {
            width: 160px;
            height: 160px;
            border-radius: 160px;
            border: 4px solid @white;
            object-fit: cover;
            scale: 0.8;
            transition: all 0.5s ease-in-out;
          }
        }
        &.slick-current {
          .slideBox {
            z-index: 2;
            img {
              scale: 1;
            }
          }
        }
      }
    }

    .slick-prev,
    .slick-next {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      background-color: transparent;
      transition: all 0.5s ease-in-out;
      svg {
        width: 50px;
        height: auto;
        path {
          fill: @text_light_black;
          transition: all 0.5s ease-in-out;
        }
      }
      &:is(:hover, :focus-within) {
        svg path {
          fill: @secondary-color;
        }
      }
      &:before,
      &:before {
        content: "";
      }
    }
    .slick-prev {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: @screen-lg) {
    .sliderContainer {
      .thumbnailSliderWrap {
        .slick-slide {
          .slideBox {
            img {
              width: 130px;
              height: 130px;
            }
          }
        }
      }
      .testimonialItem {
        padding: 20px 10px;
        p {
          font-size: 1.7rem;
          font-weight: 600;
          line-height: 1.8;
          letter-spacing: 0.032em;
        }
      }
    }
  }
}
