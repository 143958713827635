.mainFooter {
  position: relative;
  margin: 0 auto 0;
  padding: 40px 0;
  isolation: isolate;
  background-color: #171741;
  width: 100%;
  color: @white;

  .bgImg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    padding: 0;
    margin: 0;
    opacity: 0.3;
  }

  .container {
    max-width: 1400px;
    padding: 0 50px;
  }

  .contactSection {
    padding: 30px 50px;

    .contact {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      li {
        width: 30%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        /* 175% */
        letter-spacing: 0.32px;

        .icon {
          min-width: 45px;
          min-height: 45px;
          aspect-ratio: 1/1;
          border-radius: 45px;
          border: 6px solid @white;
          background-color: @white;
          transition: all 0.5s ease-in-out;
          margin-right: 20px;

          path {
            fill: @secondary-dark-color;
          }
        }

        a {
          color: @white;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          /* 175% */
          letter-spacing: 0.32px;
          width: 100%;

          strong {
            display: block;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.48px;
            position: relative;
          }

          span {
            color: @white;
          }
        }

        p {
          width: 60%;
          padding: 0;
          margin: 0;
          display: block;
        }

        &:is(:hover, :focus-within) {
          a {
            color: rgb(251, 130, 73);
          }

          svg path {
            fill: @label-color;
          }
        }
      }
    }

    @media (max-width: @screen-lg) {
      padding: 30px 5px 30px 20px;

      .contact {
        flex-wrap: wrap;
        width: 100%;
        margin-left: -15px;

        li {
          width: 100%;
          margin-bottom: 30px;
          flex-direction: column;

          p {
            width: 100%;
          }

          .icon {
            min-width: 30px;
            min-height: 30px;
            border-width: 4px;
            margin-right: 10px;
            margin-bottom: 10px;
          }

          a {
            font-size: 1.4rem;

            strong {
              font-size: 1.8rem;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .footerTitle {
    color: @white;
    font-size: 2.6rem;
    font-weight: 600;
    line-height: 1.4;
    position: relative;
    padding: 0 0 20px;
    margin: 0 0 30px;

    &::before {
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 7px;
      background-color: @white;
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
      animation: move-left-right-footer 4s ease-in-out infinite;
    }

    &::after {
      content: "";
      width: 40px;
      height: 1px;
      background-color: @white;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .footerMenu {
    margin: 0;
    padding: 0;

    li {
      width: 100%;
      margin-bottom: 20px;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        font-size: 1.6rem;
        line-height: 1.4;
        font-weight: 400;
        color: @white;

        svg {
          width: 14px;
          height: 14px;
          transition: all 0.5s ease-in-out;

          path {
            fill: @white;
          }
        }

        &:is(:hover, :focus-within) {
          color: @white;

          svg {
            transform: rotate(45deg);

            path {
              fill: @white;
            }
          }
        }
      }
    }
  }

  .summary {
    font-size: 1.6rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.4;
    color: @white;
    margin-bottom: 20px;
  }

  .firstSection {
    padding: 20px 0;

    .FooterLogo {
      width: 80%;
    }

    .siteLogo {
      width: 80%;
      max-width: 250px;
      display: block;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;

    li {
      width: 42px;
      height: 42px;
      border-radius: 42px;
      border: 1px solid @white;
      transition: all 0.5s ease-in-out;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        // padding: 20px;
        svg {
          width: 45%;
          height: 45%;

          path {
            fill: @white;
            transition: all 0.5s ease-in-out;
          }

          &.stroke {
            path {
              stroke: @white;
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }

      &:is(:hover, :focus-within) {
        background-color: @white;

        a svg {
          path {
            fill: @secondary-color;
          }

          &.stroke {
            path {
              stroke: @secondary-color;
            }
          }
        }
      }
    }
  }

  .serviceSection {
    position: relative;
  }

  .subscribeFormSection {
    background-color: @primary-color;
    border-radius: 23px;
    margin: -130px auto 50px;
    padding: 30px 40px;


    @media screen and (max-width: @screen-md) {
      padding: 20px 20px;
    }

    .footerTitle {
      color: @white;
      text-align: left;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.3;
      text-transform: capitalize;
      margin: 0;
      padding: 0px 0px 15px 0;

      @media screen and (max-width: @screen-md) {
        font-size: 28px;
        padding-bottom: 20px;
      }

    }

    .summary {
      color: @white;
      text-align: left;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 4px;
      text-transform: uppercase;

      @media screen and (max-width: @screen-md) {
        font-size: 16px;
      }
    }

    .subscribeForm {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      background-color: @white;
      border-radius: 50px;
      box-shadow: 0px 4px 22px 0px fade(@black, 7%);

      padding: 10px;

      .ant-form-item {
        margin: 0;
        position: relative;
        width: 100%;

        .ant-form-item-explain {
          position: absolute;
          left: 10px;
          bottom: -40px;

          .ant-form-item-explain-error {
            color: #641418;
          }
        }
      }

      input {
        height: 100%;
        border-radius: 50px;
        background-color: transparent;
        padding: 1rem 6%;

        &:is(:hover, :focus-within) {
          outline: none;
          box-shadow: none;
        }
      }

      button {
        height: 100%;
        border-radius: 50px;
        background-color: @black;
        padding: 1rem 5%;

        &:is(:hover, :focus-within) {
          background-color: @primary-color;
        }
      }
    }
  }

  @media (max-width: @screen-lg) {
    .container {
      padding: 0 20px;
    }

    .firstSection {
      text-align: center;

      .social {
        display: inline-flex;
        width: auto;
        margin: auto;
      }
    }
  }
}

.copyright {
  background-color: transparent;
  border-top: 1px solid rgba(255, 255, 255, 0.23);
  text-align: center;
  color: @white;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.3;
  width: 100%;
  padding: 30px 20px 15px;
  margin-top: 30px;
}