@import "../main.less";
.inner_banner {
    padding: 49px 0;
    color: @white;
    background: @innerbannerbgcolor;
    position: relative;
    @media screen and (max-width: @screen-lg){
        text-align: center;
    }
    @media screen and (max-width: @screen-md){
        padding: 25px 0;
    }

    .ant-row{
        align-items: center;
    }
    .container{
        max-width: 1200px;
    }
    h1{
        margin: 0;
        font-size: 5rem;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;

        @media screen and (max-width: @screen-lg){
            padding-bottom: 20px;
        }

        @media screen and (max-width: @screen-md){
           font-size: 4rem;
           text-align: center;
           padding-bottom: 20px;
        }
    }

    // &::before{
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     right: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-repeat: no-repeat;
    //     background-position: bottom center;
    //     background-size: cover;
    //     background-image: url(../../images/bannerleftshadow.png);
    //     max-width: 60%;
    // }

    // &::after{
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-repeat: no-repeat;
    //     background-position: left center;
    //     background-size: cover;
    //     max-width: 36%;
    //     background-image: url(../../images/bannershdow.png);

    // }
    // @media screen and (max-width: @screen-md){
    //     &:after{
    //         display: none;
    //     }
    // }

}
.heading {
    text-align: center;
    padding-bottom: 38px;
    
    @media screen and (max-width: @screen-md){
        padding-bottom: 20px;
    }
    h2{
        color: #172B43;
        font-size: 4.5rem;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin: 0;

        @media screen and (max-width: @screen-lg){
            font-size: 3.5rem;
        }

        @media screen and (max-width: @screen-md){
            font-size: 3rem;
        }
    }
    span{
        display: block;
        color: #4D5DDA;
        font-size: 2rem;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 5.7px;
        text-transform: uppercase;
        padding-bottom: 6px;
        
        @media screen and (max-width: @screen-lg){
            font-size: 1.6rem;
        }

        @media screen and (max-width: @screen-md){
            font-size: 1.5rem;
        }
    }
}
.contact_form {
    padding: 40px 0px 180px 0;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-image: url(../../images/formbg.png);
    }

    .container{
        max-width: 1200px;
    }
   

    .textraria_form{
        .ant-form-item {
            height: calc(100% - 20px);
            *{
                height: 100%;
            }
        }
        
    }
    .ant-form-item {
        margin: 0px 0px 20px 0px;
        
    }

    input, textarea{
        border-radius: 8px;
        border: 1px solid #A8A8A8;
        color: #222;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        padding: 15px 15px;
        height: auto;
        resize: none;

        @media screen and (max-width: @screen-md){
            padding: 10px 10px;
            font-size: 14px;
            border-radius: 4px;
        }

    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: inherit;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: inherit;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: inherit;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: inherit;
      }

      .captchaLeft{
        position: relative;

        button {
            position: absolute;
            top: 15px;
            right: 10px;
            padding: 0 !important;
            line-height: normal;

            @media screen and (max-width: @screen-md){
                top: 8px;
            }
            svg{
                fill: @white;
                width: 20px;
                height: 20px;
            }
        }
      }

      button, .captchaLeft{
        &.submit_button, input{
            width: 100%;
            border-radius: 6px;
            border: 1px solid @primary-color;
            background: @primary-color;
            box-shadow: none;
            color: @white;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: inherit;
            padding: 15px 15px;
            height: auto;

            @media screen and (max-width: @screen-md){
                padding: 10px 10px;
                font-size: 14px;
                border-radius: 4px;
            }
        }
        &.submit_button{
            &:hover{
                background-color: transparent !important;
                color: @primary-color !important;
                border-color: @primary-color;
            }
        }
      }
      .captch{
        input{
            color: #605746 !important;
            border-width: 0px 0px 1px 0px;
            border-color: @primary-color;
            border-radius: 0;
            text-transform: inherit;
        }
      }
      .ant-form-item-explain-error {
        font-size: 14px;
        padding: 0px 0px 10px 0px;
    }
    .captchaLeft{
        input{
            &:hover{
        border: 1px solid @primary-color;
        background: @primary-color;
        color: @white;
    }
    }
}
}

