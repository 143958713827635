@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");

@primary-color: #4d5dda;
@primary-light-color: #bfc5f7;
@secondary-color: #172b43;
@secondary-dark-color: #0e2a4b;
@accent-color: #ffd1db;
@accent-dark-color: #ff839e;
@black: #000;
@white: #fff;
@text_dark_black: #263238;
@text_light_black: #727272;

@tagColor: #3d4d5b;
@titleColor: #0c1620;

@lite_gray: #f1f1f1;

@innerbannerbgcolor: #4655C7;

@action_btn_color: #696b71;
@success-color: #52c41a;
@warning-color: #faad14;
@error-color: #f5222d;
@label-color: #181c32;
@input-bg: #f3f5f7;

@primary-font: "Open Sans", "Trebuchet MS", "Lucida Sans Unicode",
  "Lucida Grande", "Lucida Sans", Arial, sans-serif;

@gutter: 24px;
@text-color: #494949;   

// Main Header Variables
@layout-body-background: #f7f7f7;
@layout-header-background: @white;
@layout-header-color: @text-color;
@layout-sidebar-background: @primary-color;
@layout-header-height: 125px;

// Action Button Color variables
@orange-btn: #ffa500;
@red-btn: #f10000;
@blue-btn: #0f01ff;

// Main Header Logo Variables
@logo-height: 42px;

// Section Variables
@section-padding: 50px;
@screen-md: 768px;
@screen-lg: 991px;
@screen-xl: 1199px;
@screen-medidum: 1400px;
@screen-xxl: 1700px;


.themeColorOpacity(@rgbColor, @opacity) {
  background-color: rgba(@rgbColor, @opacity);
}
