@import "../main.less";

.blog_post_section {
  padding-top: 101px;
  background-image: url(../../images/pricning.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
  padding-bottom: 240px;

  @media screen and (max-width: @screen-lg) {
    padding-bottom: 200px;
  }

  @media screen and (max-width: @screen-md) {
    padding-bottom: 150px;
  }

  .blog_inner {

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 90px;
      width: 526px;
      height: 127px;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: cover;
      background-image: url(../../images/crop.png);
    }

    @media screen and (max-width: @screen-lg) {
      &:before {
        width: 336px;
        height: 97px;
      }
    }

    @media screen and (max-width: @screen-md) {
      &:before {
        display: none;
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 100px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    background-image: url(../../images/left_circle.png);
    width: 7%;
    height: 36%;
  }


  &:before {
    content: "";
    position: absolute;
    right: 0px;
    top: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    background-image: url(../../images/shadow_ball.png);
    width: 10%;
    height: 60%;
  }

  @media screen and (max-width: @screen-md) {
    padding-top: 50px;
  }

  .container {
    max-width: 1240px;
  }

  .blog_inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    align-items: flex-start;
    
    @media screen and (max-width: @screen-md) {
      gap: 0;
    }

    .read_more_button {
      margin-top: 30px;

      @media screen and (max-width: @screen-lg) {
        margin-top: 20px;
      }
    }
  }

  .blog {
    flex: 0 0 calc(50% - 12px);
    display: flex;
    flex-wrap: wrap;
    border-radius: 0px 0px 6px 6px;
    background: @white;
    box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    margin-bottom: 10px;

    @media screen and (max-width: @screen-md) {
      flex: 0 0 calc(50% - 5px);
    }

    @media screen and (max-width: 479px) {
      flex: 0 0 100%;
      margin-bottom: 30px;
    }

  }

  .left_side {
    flex: 0 0 100%;
    border-radius: 6px 6px 0px 0px;
    overflow: hidden;
    max-height: 305px;



    img {
      width: 100%;
      // height: 100%;
      // object-fit: cover;
    }
  }

  .right_side {
    flex: 0 0 100%;
    padding: 24px 26px;

    @media screen and (max-width: @screen-md) {
      padding: 10px 10px;
    }

    .title {
      font-size: 2.4rem;

      @media screen and (max-width: @screen-md) {
        font-size: 1.8rem;
      }
    }

  }


  .date {
    border-radius: 3px;
    background-color: @innerbannerbgcolor;
    color: @white;
    font-size: 1.5rem;
    padding: 5px 10px 5px 30px;
    display: inline-block;
    background-position: left 10px center;
    background-repeat: no-repeat;
    background-size: auto;
    background-image: url(../../images/date.svg);

    @media screen and (max-width: @screen-md) {
      font-size: 1.2rem;
      border-radius: 2px;
    }
  }

  .title {
    margin: 0;
    padding: 15px 0px 7px 0px;
    color: #072B47;
    font-size: 3rem;

    @media screen and (max-width: @screen-lg) {
      padding: 10px 0px 5px 0px;
      font-size: 2.4rem;
    }

    @media screen and (max-width: @screen-md) {
      font-size: 2rem;
    }

    a {
      &:hover {
        color: #f16a24;
      }
    }
  }

  p,
  li {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.4;
    color: @text_light_black;
    padding: 10px 0px;

    @media screen and (max-width: @screen-md) {
      font-size: 1.4rem;
    }

    strong {
      color: @black;
    }
  }

  .content_block {
    li {
      padding-left: 40px;
      background-repeat: no-repeat;
      background-position: left center;
      background-image: url(../../images/blogdetails.png);
      background-size: 20px;
    }

    .tags {
      padding-top: 10px;

      p {
        padding: 2px 0px;
      }
    }
  }

  .button {
    display: block;
    margin-top: 40px;
    border-top: solid 1px #ccc;
    padding-top: 18px;

    @media screen and (max-width: @screen-md) {
      margin-top: 20px;
      padding-top: 10px;
    }

    a {
      font-size: 1.6rem;
      font-weight: 700;
      color: @primary-color;
      text-transform: capitalize;
      background-repeat: no-repeat;
      background-position: right center;
      background-image: url(../../images/arrow.svg);
      background-size: 15px;
      padding-right: 25px;
      position: relative;
      transition: all 0.5s ease-in-out;

      @media screen and (max-width: @screen-md) {
        font-size: 1.4rem;
      }

      &:after {
        content: "";
        height: 2px;
        background-color: @primary-color;
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 0;
        transition: all 0.5s ease-in-out;
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }

  .descreption_side {
    flex: 0 0 calc(67% - 12px);

    @media screen and (max-width: @screen-md) {
      flex: 0 0 100%;
    }

    .image_block {
      line-height: 0;
    }

    img {
      // width: 100%;
      // object-fit: cover;
      max-height: 417px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // margin: 0 auto;
    }

    .content_block {
      background-color: @white;
      padding: 22px 36px;
      border-radius: 0px 0px 6px 6px;
      box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.05);

      @media screen and (max-width: @screen-md) {
        padding: 20px 20px;
      }

      ul {
        margin: inherit;
        padding: inherit;
        list-style: inherit;

        &.line {
          list-style: none;

          li {
            margin: 0;
          }
        }

        li {
          list-style: inherit;
          background-image: inherit;
          padding: inherit;
          margin-left: 30px;
        }
      }

      ol {
        li {
          list-style: inherit;
          background-image: inherit;
          padding: inherit;
          padding: 0;
        }
      }
    }

    h2 {
      margin: 0;
      padding-top: 10px;
      font-size: 2.4rem;

      @media screen and (max-width: @screen-md) {
        font-size: 1.8rem;
      }
    }

    h3 {
      margin: 0;
      padding-top: 10px;
      font-size: 2rem;
      color: #263238;

      @media screen and (max-width: @screen-md) {
        font-size: 1.6rem;
      }
    }

    img {
      padding-top: 10px;
    }

    a {
      font-weight: 700;
    }

    .image_block {
      img {
        width: 100%;
      }
    }
  }

  .recent_post_block {
    .heading {
      font-size: 2.2rem;
      color: #072B47;
      text-transform: capitalize;
      padding-bottom: 15px;
      position: relative;
      margin: 0;
      text-align: left;

      @media screen and (max-width: @screen-md) {
        font-size: 2rem;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        width: 100%;
        max-width: 63px;
        background-color: #5D61E2;
      }
    }
  }

  .right_asidebar {
    flex: 0 0 calc(33% - 22px);

    @media screen and (max-width: @screen-md) {
      flex: 0 0 100%;
      margin-top: 20px;
    }

    .blogo_list {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0px;
      border-bottom: solid 1px #e8e8e9;

      @media screen and (max-width: @screen-md) {
        padding: 10px 0;
      }

      .image {
        width: 108px;
        height: 108px;
        overflow: hidden;
        border-radius: 100%;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }

        @media screen and (max-width: @screen-md) {
          width: 80px;
          height: 80px;
        }
      }

      .content {
        flex: 0 0 calc(100% - 120px);

        .title {
          padding-top: 0;
          font-size: 1.8rem;
        }

        @media screen and (max-width: @screen-md) {
          flex: 0 0 calc(100% - 100px);
          font-size: 1.4rem;
        }
      }
    }

    .recent_post_block {
      width: 100%;
      margin-bottom: 52px;

      @media screen and (max-width: @screen-md) {
        margin-bottom: 20px;
      }

      ul {
        margin-top: 30px;

        @media screen and (max-width: @screen-md) {
          margin-top: 15px;
        }

        li {
          border: 1px solid rgba(114, 114, 114, 0.48);
          border-radius: 4px;
          padding: 10px 15px;
          line-height: normal;
          color: @black;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
          transition: all 0.5s ease-in-out;
          display: inline-block;
          margin: 10px;

          @media screen and (max-width: @screen-md) {
            margin: 5px;
            padding: 10px 10px;
          }

          &:hover {
            background-color: @blue-btn;
            border-color: @blue-btn;
            color: @white;
          }
        }
      }
    }
  }
}