@import "../variables.less";

// counterSection
#counterSection {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0 0;
  isolation: isolate;

  .container {
    max-width: 1400px;
    display: block;
    margin: auto;
    .ant-row {
      width: 100%;
    }
  }
  .boxes {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 80px;
  }

  .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    width: 25%;
    .countUp {
      display: block;
      text-align: center;
      color: @secondary-color;
      font-size: 8rem;
      font-weight: 600;
      line-height: 1.3;
      letter-spacing: 0.16rem;
    }
    .title {
      display: flex;
      align-items: center;
      min-height: 50px;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.3;
      letter-spacing: 0.032em;
      text-transform: uppercase;
    }
    .counterProgress {
      .ant-progress-inner {
        overflow: visible;
        background-color: fade(@black, 10%);
      }
      .ant-progress-bg {
        background-color: currentColor;
        filter: drop-shadow(0px 4px 15px currentColor);
      }
    }
  }

  @media (max-width: @screen-lg) {
    .boxes {
      flex-wrap: wrap;
      gap: 20px 40px;
      .counter {
        width: calc(50% - 20px);
        .countUp {
          font-size: 4.5rem;
          font-weight: 800;
        }
      }
    }
  }
}
