.mainWrapper {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}
.mainContent {
  padding-top: 140px;

  @media (max-width: @screen-xxl) {
    padding-top: 120px;
  }

  @media (max-width: @screen-lg) {
    padding-top: 90px;
  }
}
.mainHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 140px;
  background-color: transparent;
  transition: all 0.5s ease-in-out;

  &.fixed {
    top: 0;
    background-color: fadeout(@white, 10%);
    box-shadow: 8px 4px 16px rgba(0, 0, 0, 0.08);
    height: 100px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: relative;
    transition: all 0.5s ease-in-out;

    @media (max-width: @screen-lg) {
      padding: 0 !important;
    }
  }

  .siteLogo {
    position: relative;
    width: 100%;
    max-width: 25%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img {
      max-width: 300px;
    }
  }
  .headerNavSection {
    display: flex;
    align-items: flex-start;
    flex: 1;
    max-width: 75%;

    .menuStrip {
      background-color: transparent;
      width: 100%;
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nav {
        display: grid;
        grid-template-columns: auto;
        grid-auto-flow: column;
        a {
          display: inline-block;
          font-size: 1.6rem;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 0.032em;
          transition: all 500ms linear;
          white-space: nowrap;
          color: @secondary-color;
          margin: 0 30px;
          &:before {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
            transition: all 500ms linear;
          }
          &:is(:hover, :focus-within, .active) {
            color: @primary-color;
          }
        }
      }

      .actionBtns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 3rem;
        .buttonPrimary {
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 1.4;
          text-align: center;
          text-transform: capitalize;
          color: @white;
          background-color: @primary-color;
          border-radius: 6px;
          padding: 1rem 2rem;
          margin: 0;

          &:is(:hover, :focus-within) {
            background-color: @secondary-dark-color;
          }
        }
      }
    }
    #hamburgerIcon {
      max-width: 25%;
      display: none;
      margin-right: 20px;
      position: relative;
      z-index: 99999;
    }
  }

  @media (max-width: @screen-xxl) {
    height: 120px;
    .siteLogo {
      max-width: 20%;
      .img {
        max-width: 100%;
      }
    }
    .headerNavSection {
      max-width: 75%;
      .menuStrip {
        justify-content: flex-end;
        gap: 20px;
        .nav {
          a {
            font-size: 1.6rem;
            margin: 0 20px;
          }
        }
        .actionBtns .buttonPrimary {
          font-size: 1.6rem;
        }
      }
    }
  }
  @media (max-width: @screen-medidum) {
  .headerNavSection {
    .menuStrip {
      .actionBtns .buttonPrimary {
        font-size: 1.4rem;
      }
    }
  }
  }
  @media (max-width: @screen-lg) {
  .siteLogo {
    .img {
      max-width: 80%;
    }
  }
}
  @media (max-width: @screen-medidum) {
    .headerNavSection {
      .menuStrip {

        .nav {
          a {
            font-size: 1.5rem;
            margin: 0 15px;
          }
        }
  
      }
    }
  }

  @media (max-width: @screen-xl) {
    .container {
      padding: 0;
    }
    .siteLogo {
      max-width: 180px;
    }
    .headerNavSection {
      .menuStrip {
        .nav {
          a {
            font-size: 1.2rem;
            margin: 0 10px;
          }
        }
        .actionBtns {
          .buttonPrimary {
            white-space: nowrap;
            font-size: 1.5rem;
          }
        }
      }
    }
  }


  
  @media (max-width: @screen-lg) {
    padding: 0 15px;  
    transition: all 0.3s ease-in-out;
    top: 0;
    height: auto;
    .container {
      align-items: flex-start;
      padding-top: 13px;
    }
    &.fixed {
      .container {
        padding-top: 0px;
      }
    }
    .siteLogo {
      max-width: 230px;
      z-index: 9999;
      top: 20px;
      img {
        max-width: 100%;
        object-fit: contain;
        object-position: center center;
      }
    }
    .headerNavSection {
      max-width: 100%;
      align-items: flex-end;
      flex: 0;

      .menuStrip {
        order: 3;
        height: 100dvh;
        padding: 0;
        opacity: 0;
        position: fixed;
        top: -140%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 30px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 17%,
          rgba(208, 208, 208, 1) 100%
        );
        transition: all 0.6s ease-in-out;
        // gap: 3rem;
        .nav {
          display: flex;
          flex-direction: column;

          a {
            text-align: center;
            font-size: 1.9rem;
          }
        }
        .actionBtns {
          .buttonPrimary {
            padding: 1.5rem 2rem;
            font-size: 1.6rem;
          }
        }
      }
      #hamburgerIcon {
        display: block;
        order: 2;
        max-width: 100%;
        padding: 0;
        margin: 24px 0 0;
      }
    }

    &.menuOpen {
      position: fixed;
      top: 0;
      width: 100dvw;
      height: 100dvh;
      z-index: 9999;
      transition: all 0.3s ease-in-out;

      .headerNavSection {
        .menuStrip {
          opacity: 1;
          top: 0px;
        }
        #hamburgerIcon {
          margin-top: 20px;
        }
      }
    }
  }

  @media screen and (max-height: 600px) and (max-width: @screen-lg) {
    .headerNavSection {
      .menuStrip {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
        padding: 0 60px;
        .nav,
        .actionBtns {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          gap: 3rem 4rem;
          line-height: 1.2;
        }
      }
    }
  }
}

#hamburgerIcon {
  transition: all 0.3s ease-in-out;
  margin-top: 5px;
  .line {
    width: 40px;
    height: 5px;
    background-color: @text_dark_black;
    display: block;
    margin: 8px auto;
    transition: all 0.3s ease-in-out;
  }

  &:is(:hover, :focus-within) {
    cursor: pointer;
  }
  &.is-active {
    animation: smallbig 0.6s forwards;
    & .line:nth-child(1),
    & .line:nth-child(2),
    & .line:nth-child(3) {
      transition-delay: 0.2s;
    }

    & .line:nth-child(2) {
      opacity: 0;
    }

    & .line:nth-child(1) {
      transform: translateY(13px) rotate(45deg);
    }

    & .line:nth-child(3) {
      transform: translateY(-13px) rotate(-45deg);
    }
  }
}

@keyframes smallbig {
  0%,
  100% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(0);
  }
}
